<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <base-material-card
          color="primary"
          icon="mdi-database-eye-outline"
          inline
          class="px-5 py-3"
        >
          <template v-slot:after-heading>
            <div class="display-2 font-weight-light">
              Painel Unificado de Validações
            </div>
          </template>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div
                style="float: right !important"
                class="mt-n9"
                v-bind="attrs"
                v-on="on"
              >
                <v-btn
                  icon
                  color="black"
                  :loading="loadingResumoValidacoes"
                  @click="baixarResumoValidacoes()"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon size="22"> mdi-download-circle-outline </v-icon>
                </v-btn>
              </div>
            </template>
            Baixar o resumo das validações
          </v-tooltip>
          <v-row>
            <v-col cols="12">
              <v-progress-circular
                v-if="loading"
                size="20"
                indeterminate
                color="rgba(0, 0, 0, 0.87)"
              />
              <div v-else>
                <v-row>
                  <v-col
                    cols="12"
                    class="d-flex justify-space-between bg-surface-variant"
                  >
                    <v-switch
                      v-model="exibeDemaisStatus"
                      hide-details
                      inset
                      label="Exibir Exceções e Análises Futuras"
                    />
                    <div style="float: right !important">
                      <router-link to="/home">Status da BDGD</router-link>
                      <router-link
                        to="/geracao-bdgd/validacoes-indqual/painel-unificado"
                        class="ml-4"
                        >Painel Unificado INDQUAL
                      </router-link>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-card tile>
                      <v-subheader> Total de erros críticos </v-subheader>
                      <v-divider />
                      <v-card-text>
                        <div
                          class="d-flex justify-center align-center"
                          style="height: 200px"
                        >
                          <h1 class="texto-erro-critico">
                            {{ totalErrosCriticos | parseNumberToIntegerBR }}
                          </h1>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="6">
                    <v-card tile>
                      <v-subheader> Total de erros não críticos </v-subheader>
                      <v-divider />
                      <v-card-text>
                        <div
                          class="d-flex justify-center align-center"
                          style="height: 200px"
                        >
                          <h1 class="texto-erro-nao-critico">
                            {{ totalErrosNaoCriticos | parseNumberToIntegerBR }}
                          </h1>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row
                  v-if="
                    exibeDemaisStatus && (totalExcecoes || totalAnalisesFuturas)
                  "
                >
                  <v-col cols="6">
                    <v-card tile>
                      <v-subheader> Total de exceções </v-subheader>
                      <v-divider />
                      <v-card-text>
                        <div
                          class="d-flex justify-center align-center"
                          style="height: 200px"
                        >
                          <h1 class="texto-excecoes">
                            {{ totalExcecoes | parseNumberToIntegerBR }}
                          </h1>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="6">
                    <v-card tile>
                      <v-subheader> Total de análises futuras </v-subheader>
                      <v-divider />
                      <v-card-text>
                        <div
                          class="d-flex justify-center align-center"
                          style="height: 200px"
                        >
                          <h1 class="texto-analises-futuras">
                            {{ totalAnalisesFuturas | parseNumberToIntegerBR }}
                          </h1>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-card tile>
                      <v-subheader>
                        Erros críticos por empresa (%)
                      </v-subheader>
                      <v-divider />
                      <v-card-text>
                        <grafico-pizza-total-erros-bdgds
                          campoErros="qtd_erros_criticos_encontrados"
                          :totalErros="totalErrosCriticos"
                          :resumoValidacoesBdgds="resumoValidacoesBdgds"
                        />
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="6">
                    <v-card tile>
                      <v-subheader>
                        Erros não críticos por empresa (%)
                      </v-subheader>
                      <v-divider />
                      <v-card-text>
                        <grafico-pizza-total-erros-bdgds
                          campoErros="qtd_erros_nao_criticos_encontrados"
                          :totalErros="totalErrosNaoCriticos"
                          :resumoValidacoesBdgds="resumoValidacoesBdgds"
                        />
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row
                  v-if="
                    exibeDemaisStatus && (totalExcecoes || totalAnalisesFuturas)
                  "
                >
                  <v-col cols="6">
                    <v-card tile>
                      <v-subheader> Exceções por empresa (%) </v-subheader>
                      <v-divider />
                      <v-card-text>
                        <grafico-pizza-total-erros-bdgds
                          campoErros="qtd_excecoes"
                          :totalErros="totalExcecoes"
                          :resumoValidacoesBdgds="resumoValidacoesBdgds"
                        />
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="6">
                    <v-card tile>
                      <v-subheader>
                        Análises futuras por empresa (%)
                      </v-subheader>
                      <v-divider />
                      <v-card-text>
                        <grafico-pizza-total-erros-bdgds
                          campoErros="qtd_analises_futuras"
                          :totalErros="totalAnalisesFuturas"
                          :resumoValidacoesBdgds="resumoValidacoesBdgds"
                        />
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-card tile>
                      <v-subheader> Erros críticos por etapa NVAL </v-subheader>
                      <v-divider />
                      <v-card-text>
                        <grafico-waterfall-total-erros-bdgds
                          campoErros="qtd_erros_criticos_encontrados"
                          corBarras="#e91e63"
                          :resumoValidacoesBdgds="resumoValidacoesBdgds"
                        />
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="6">
                    <v-card tile>
                      <v-subheader>
                        Erros não críticos por etapa NVAL
                      </v-subheader>
                      <v-divider />
                      <v-card-text>
                        <grafico-waterfall-total-erros-bdgds
                          campoErros="qtd_erros_nao_criticos_encontrados"
                          corBarras="#f9a825"
                          :resumoValidacoesBdgds="resumoValidacoesBdgds"
                        />
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row
                  v-if="
                    exibeDemaisStatus && (totalExcecoes || totalAnalisesFuturas)
                  "
                >
                  <v-col cols="6">
                    <v-card tile>
                      <v-subheader> Exceções por etapa NVAL </v-subheader>
                      <v-divider />
                      <v-card-text>
                        <grafico-waterfall-total-erros-bdgds
                          campoErros="qtd_excecoes"
                          corBarras="green"
                          :resumoValidacoesBdgds="resumoValidacoesBdgds"
                        />
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="6">
                    <v-card tile>
                      <v-subheader>
                        Análises futuras por etapa NVAL
                      </v-subheader>
                      <v-divider />
                      <v-card-text>
                        <grafico-waterfall-total-erros-bdgds
                          campoErros="qtd_analises_futuras"
                          corBarras="#ff5722"
                          :resumoValidacoesBdgds="resumoValidacoesBdgds"
                        />
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="6"
                    v-for="(versaoBdgdValidada, i) in versoesBdgdsValidadas"
                    :key="i"
                  >
                    <card-resumo-status-etapas-nval
                      :empresa="versaoBdgdValidada.empresa"
                      :bdgdVersaoId="versaoBdgdValidada.bdgdVersaoId"
                      :dadosValidacoes="
                        resumoValidacoesBdgds[versaoBdgdValidada.empresa][
                          versaoBdgdValidada.bdgdVersaoId
                        ]
                      "
                      :exibeDemaisStatus="exibeDemaisStatus"
                    />
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import dayjs from 'dayjs';
import ValidacoesBdgdsService from '@/services/ValidacoesBdgdsService';
import CardResumoStatusEtapasNval from '@/components/geracao-bdgd/validacoes-bdgd/CardResumoStatusEtapasNval';
import GraficoPizzaTotalErrosBdgds from '@/components/geracao-bdgd/validacoes-bdgd/GraficoPizzaTotalErrosBdgds';
import GraficoWaterfallTotalErrosBdgds from '@/components/geracao-bdgd/validacoes-bdgd/GraficoWaterfallTotalErrosBdgds';

export default {
  name: 'PainelUnificado',
  components: {
    CardResumoStatusEtapasNval,
    GraficoPizzaTotalErrosBdgds,
    GraficoWaterfallTotalErrosBdgds
  },
  data: () => ({
    loading: false,
    loadingResumoValidacoes: false,
    resumoValidacoesBdgds: {},
    exibeDemaisStatus: false
  }),
  mounted() {
    this.getResumoValidacoesBdgds();
  },
  computed: {
    empresasValidadas() {
      return Object.keys(this.resumoValidacoesBdgds);
    },
    versoesBdgdsValidadas() {
      let versoes = [];
      this.empresasValidadas.forEach((empresa) => {
        const versoesBdgdsEmpresa = Object.keys(
          this.resumoValidacoesBdgds[empresa]
        );
        versoesBdgdsEmpresa.forEach((bdgdVersaoId) =>
          versoes.push({
            empresa,
            bdgdVersaoId: Number(bdgdVersaoId)
          })
        );
      });
      return versoes;
    },
    totalErrosCriticos() {
      let totalErrosCriticos = 0;
      this.versoesBdgdsValidadas.forEach((versaoBdgd) => {
        const dadosValidacoes =
          this.resumoValidacoesBdgds[versaoBdgd.empresa][
            versaoBdgd.bdgdVersaoId
          ];
        totalErrosCriticos += dadosValidacoes.reduce(
          (acumulador, item) =>
            (acumulador += Number(item.qtd_erros_criticos_encontrados)),
          0
        );
      });
      return totalErrosCriticos;
    },
    totalErrosNaoCriticos() {
      let totalErrosNaoCriticos = 0;
      this.versoesBdgdsValidadas.forEach((versaoBdgd) => {
        const dadosValidacoes =
          this.resumoValidacoesBdgds[versaoBdgd.empresa][
            versaoBdgd.bdgdVersaoId
          ];
        totalErrosNaoCriticos += dadosValidacoes.reduce(
          (acumulador, item) =>
            (acumulador += Number(item.qtd_erros_nao_criticos_encontrados)),
          0
        );
      });
      return totalErrosNaoCriticos;
    },
    totalExcecoes() {
      let totalExcecoes = 0;
      this.versoesBdgdsValidadas.forEach((versaoBdgd) => {
        const dadosValidacoes =
          this.resumoValidacoesBdgds[versaoBdgd.empresa][
            versaoBdgd.bdgdVersaoId
          ];
        totalExcecoes += dadosValidacoes.reduce(
          (acumulador, item) => (acumulador += Number(item.qtd_excecoes)),
          0
        );
      });
      return totalExcecoes;
    },
    totalAnalisesFuturas() {
      let totalAnalisesFuturas = 0;
      this.versoesBdgdsValidadas.forEach((versaoBdgd) => {
        const dadosValidacoes =
          this.resumoValidacoesBdgds[versaoBdgd.empresa][
            versaoBdgd.bdgdVersaoId
          ];
        totalAnalisesFuturas += dadosValidacoes.reduce(
          (acumulador, item) =>
            (acumulador += Number(item.qtd_analises_futuras)),
          0
        );
      });
      return totalAnalisesFuturas;
    }
  },
  methods: {
    getResumoValidacoesBdgds() {
      this.loading = true;
      ValidacoesBdgdsService.getResumoValidacoesBdgds()
        .then(({ data }) => (this.resumoValidacoesBdgds = data))
        .catch((err) => {
          console.log('Erro: ', err);
          this.$toast.error(
            'Erro ao buscar o resumo das validações realizadas nas BDGDs das distribuidoras.',
            '',
            { position: 'topRight' }
          );
        })
        .finally(() => (this.loading = false));
    },
    baixarResumoValidacoes() {
      this.loadingResumoValidacoes = true;
      ValidacoesBdgdsService.baixarResumoValidacoesBdgds()
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          const timestamp = dayjs(new Date()).format('YYYYMMDDHHmm');
          const arquivo = `resumo-validacoes-bdgds-${timestamp}.xlsx`;
          link.setAttribute('download', arquivo);
          document.body.appendChild(link);
          link.click();
        })
        .catch(() => {
          this.$toast.error(
            'Falha no download do resumo de validações das BDGDs.',
            '',
            { position: 'topRight' }
          );
        })
        .finally(() => (this.loadingResumoValidacoes = false));
    }
  }
};
</script>

<style scoped>
.texto-erro-critico {
  font-size: 90px;
  color: #e91e63;
}
.texto-erro-nao-critico {
  font-size: 90px;
  color: #f9a825;
}
.texto-excecoes {
  font-size: 90px;
  color: green;
}
.texto-analises-futuras {
  font-size: 90px;
  color: #ff5722;
}
</style>
