<template>
  <highcharts
    :options="chartOptions"
    style="height: 300px !important"
  />
</template>

<script>
import Highcharts from 'highcharts';

export default {
  name: 'GraficoPizzaTotalErrosBdgds',
  props: {
    campoErros: {
      type: String,
      required: true,
      default: 'qtd_erros_criticos_encontrados'
    },
    totalErros: {
      type: Number,
      required: true
    },
    resumoValidacoesBdgds: {
      type: Object,
      required: true
    }
  },
  computed: {
    empresasValidadas() {
      return Object.keys(this.resumoValidacoesBdgds);
    },
    errosAgrupadosPorEmpresa() {
      let errosAgrupadosPorEmpresa = {};
      this.empresasValidadas.forEach((empresa) => {
        errosAgrupadosPorEmpresa[empresa] = 0;
        const versoesBdgdsEmpresa = Object.keys(
          this.resumoValidacoesBdgds[empresa]
        );
        versoesBdgdsEmpresa.forEach(
          (bdgdVersaoId) =>
            (errosAgrupadosPorEmpresa[empresa] += this.resumoValidacoesBdgds[
              empresa
            ][bdgdVersaoId].reduce(
              (acumulador, item) =>
                (acumulador += Number(item[this.campoErros])),
              0
            ))
        );
      });
      return errosAgrupadosPorEmpresa;
    },
    chartOptions() {
      return {
        chart: {
          type: 'pie',
          style: {
            fontFamily: 'muli'
          }
        },
        title: {
          text: null
        },
        credits: {
          enabled: false
        },
        tooltip: {
          pointFormat: '<b>{point.erros} - {point.percentage:.1f}%</b>'
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: true,
              format: '<b>{point.name}</b>: {point.percentage:.1f} %',
              style: {
                color:
                  (Highcharts.theme && Highcharts.theme.contrastTextColor) ||
                  'black'
              }
            },
            showInLegend: false
          }
        },
        series: [
          {
            name: 'Percentual',
            colorByPoint: true,
            data: this.seriesData
          }
        ]
      };
    },
    seriesData() {
      const seriesData = [];
      this.empresasValidadas.forEach((empresa) => {
        const percentual =
          this.errosAgrupadosPorEmpresa[empresa] / this.totalErros;
        seriesData.push({
          name: empresa,
          erros: parseInt(
            this.errosAgrupadosPorEmpresa[empresa]
          ).toLocaleString('pt-BR'),
          y: Math.floor(percentual * 100)
        });
      });
      return seriesData;
    }
  }
};
</script>
