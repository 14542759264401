import APIService from './APIService';

export default {
  getValidacoes() {
    return new Promise((resolve, reject) => {
      APIService.apiCall()
        .get('/validacoes-bdgds/index')
        .then((response) => resolve(response.data.data))
        .catch((error) => reject(error));
    });
  },

  save(postData) {
    return APIService.apiCall().post(
      '/validacoes-bdgds/save',
      JSON.stringify(postData)
    );
  },

  getDatesAndRegistersImportedEntities() {
    return APIService.apiCall().get(
      '/validacoes-bdgds/getDatesAndRegistersImportedEntities'
    );
  },

  getImportedEntitiesAndRegistersByDate(date, companyId, bdgdVersionId) {
    return APIService.apiCall().get(
      `/validacoes-bdgds/getImportedEntitiesAndRegistersByDate/${date}/${companyId}/${bdgdVersionId}`
    );
  },

  getTestesAtivos() {
    return APIService.apiCall().get('/validacoes-bdgds/getTestesAtivos');
  },

  baixarZip(id, config = {}) {
    return APIService.apiCall().get(
      `/validacoes-bdgds/downloadArquivoResultado/${id}`,
      { responseType: 'blob', ...config }
    );
  },

  getResumoValidacoesBdgd(bdgdVersionId, somentePreProtocolo = false) {
    return APIService.apiCall().get(
      `/validacoes-bdgds/getResumoValidacoesBdgd/${bdgdVersionId}?somentePreProtocolo=${somentePreProtocolo}`
    );
  },

  getResumoValidacoesBdgds() {
    return APIService.apiCall().get(
      '/validacoes-bdgds/getResumoValidacoesBdgds'
    );
  },

  baixarResumoValidacoesBdgds(config = {}) {
    return APIService.apiCall().get(
      '/validacoes-bdgds/baixarResumoValidacoesBdgds',
      { responseType: 'blob', ...config }
    );
  },

  getResultadoUltimaExecucaoTeste(bdgdVersionId, siglaTeste) {
    return APIService.apiCall().get(
      `/validacoes-bdgds/getResultadoUltimaExecucaoTeste/${bdgdVersionId}/${siglaTeste}`
    );
  },

  getHistoricoExecucoesTeste(bdgdVersionId, siglaTeste) {
    return APIService.apiCall().get(
      `/validacoes-bdgds/getHistoricoExecucoesTeste/${bdgdVersionId}/${siglaTeste}`
    );
  },

  getErrosValidacao(siglaTeste, validacaoBdgdId, postData, limit = 1000) {
    const url = `/validacoes-bdgds/getErrosValidacao/${siglaTeste}/${validacaoBdgdId}`;
    return APIService.apiCall().post(
      url,
      JSON.stringify({ ...postData, limit })
    );
  },

  baixarErrosValidacao(siglaTeste, validacaoBdgdId, postData, config = {}) {
    const url = `/validacoes-bdgds/baixarErrosValidacao/${siglaTeste}/${validacaoBdgdId}/xlsx`;
    return APIService.apiCall().post(url, JSON.stringify({ ...postData }), {
      responseType: 'blob',
      ...config
    });
  },

  baixarErrosValidacaoFormatoShapefile(
    siglaTeste,
    validacaoBdgdId,
    postData,
    config = {}
  ) {
    const url = `/validacoes-bdgds/baixarErrosValidacao/${siglaTeste}/${validacaoBdgdId}/shapefile`;
    return APIService.apiCall().post(url, JSON.stringify({ ...postData }), {
      responseType: 'blob',
      ...config
    });
  },

  baixarSqlErrosValidacao(siglaTeste, validacaoBdgdId, postData, config = {}) {
    const url = `/validacoes-bdgds/baixarErrosValidacao/${siglaTeste}/${validacaoBdgdId}/sql`;
    return APIService.apiCall().post(url, JSON.stringify({ ...postData }), {
      responseType: 'blob',
      ...config
    });
  },

  baixarHistoricoValidacoes(bdgdVersionId, somentePreProtocolo = false, config = {}) {
    return APIService.apiCall().get(
      `/validacoes-bdgds/baixarHistoricoValidacoes/${bdgdVersionId}?somentePreProtocolo=${somentePreProtocolo}`,
      { responseType: 'blob', ...config }
    );
  }
};
