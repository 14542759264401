<template>
  <v-card tile>
    <v-subheader>
      {{ titulo }}
    </v-subheader>
    <v-divider />
    <v-card-text>
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th colspan="2">Etapa</th>
              <th class="text-right">Críticos</th>
              <th class="text-right">Não críticos</th>
              <th
                class="text-right"
                v-if="exibeDemaisStatus && totalExcecoesEncontradas"
              >
                Exceções
              </th>
              <th
                class="text-right"
                v-if="exibeDemaisStatus && totalAnalisesFuturas"
              >
                Análises
              </th>
              <th class="text-right">Último teste em</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(resultado, i) in dadosValidacoes"
              :key="i"
              :style="getStyleByStatus(resultado)"
            >
              <td>
                <v-icon :style="getStyleByStatus(resultado)">
                  {{ getIconByStatus(resultado) }}
                </v-icon>
              </td>
              <td>
                {{ resultado.etapa_nval }}
              </td>
              <td class="text-right">
                {{
                  resultado.qtd_erros_criticos_encontrados
                    | parseNumberToIntegerBR
                }}
              </td>
              <td class="text-right">
                {{
                  resultado.qtd_erros_nao_criticos_encontrados
                    | parseNumberToIntegerBR
                }}
              </td>
              <td
                class="text-right"
                v-if="exibeDemaisStatus && totalExcecoesEncontradas"
              >
                {{ resultado.qtd_excecoes | parseNumberToIntegerBR }}
              </td>
              <td
                class="text-right"
                v-if="exibeDemaisStatus && totalAnalisesFuturas"
              >
                {{ resultado.qtd_analises_futuras | parseNumberToIntegerBR }}
              </td>
              <td class="text-right">
                {{ resultado.data_ultima_validacao | parseToDateTimeBR }}
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td colspan="2"></td>
              <td
                class="text-right"
                :style="
                  getStyleByStatus({
                    qtd_erros_criticos_encontrados:
                      totalErrosCriticosEncontrados,
                    qtd_erros_nao_criticos_encontrados: 0,
                    qtd_excecoes: 0,
                    qtd_analises_futuras: 0
                  })
                "
              >
                {{ totalErrosCriticosEncontrados | parseNumberToIntegerBR }}
              </td>
              <td
                class="text-right"
                :style="
                  getStyleByStatus({
                    qtd_erros_criticos_encontrados: 0,
                    qtd_erros_nao_criticos_encontrados:
                      totalErrosNaoCriticosEncontrados,
                    qtd_excecoes: 0,
                    qtd_analises_futuras: 0
                  })
                "
              >
                {{ totalErrosNaoCriticosEncontrados | parseNumberToIntegerBR }}
              </td>
              <td
                class="text-right"
                v-if="exibeDemaisStatus && totalExcecoesEncontradas"
                :style="
                  getStyleByStatus({
                    qtd_erros_criticos_encontrados: 0,
                    qtd_erros_nao_criticos_encontrados: 0,
                    qtd_excecoes: totalExcecoesEncontradas,
                    qtd_analises_futuras: 0
                  })
                "
              >
                {{ totalExcecoesEncontradas | parseNumberToIntegerBR }}
              </td>
              <td
                class="text-right"
                v-if="exibeDemaisStatus && totalAnalisesFuturas"
                :style="
                  getStyleByStatus({
                    qtd_erros_criticos_encontrados: 0,
                    qtd_erros_nao_criticos_encontrados: 0,
                    qtd_excecoes: 0,
                    qtd_analises_futuras: totalAnalisesFuturas
                  })
                "
              >
                {{ totalAnalisesFuturas | parseNumberToIntegerBR }}
              </td>
              <td></td>
            </tr>
          </tfoot>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import dayjs from 'dayjs';

export default {
  name: 'CardResumoStatusEtapasNval',
  props: {
    empresa: {
      type: String,
      required: true
    },
    bdgdVersaoId: {
      type: Number,
      required: true
    },
    dadosValidacoes: {
      type: Array,
      required: true
    },
    exibeDemaisStatus: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    mesDados() {
      return this.dadosValidacoes[0]['mes_dados'];
    },
    mesDadosBr() {
      return dayjs(this.mesDados).format('MM/YYYY');
    },
    bdgdVersao() {
      return this.dadosValidacoes[0]['bdgd_versao'];
    },
    titulo() {
      return `${this.empresa} :: ${this.mesDadosBr} v${this.bdgdVersao}`;
    },
    totalErrosCriticosEncontrados() {
      return this.dadosValidacoes.reduce(
        (acumulador, item) =>
          (acumulador += Number(item.qtd_erros_criticos_encontrados)),
        0
      );
    },
    totalErrosNaoCriticosEncontrados() {
      return this.dadosValidacoes.reduce(
        (acumulador, item) =>
          (acumulador += Number(item.qtd_erros_nao_criticos_encontrados)),
        0
      );
    },
    totalExcecoesEncontradas() {
      return this.dadosValidacoes.reduce(
        (acumulador, item) => (acumulador += Number(item.qtd_excecoes)),
        0
      );
    },
    totalAnalisesFuturas() {
      return this.dadosValidacoes.reduce(
        (acumulador, item) => (acumulador += Number(item.qtd_analises_futuras)),
        0
      );
    }
  },
  methods: {
    getStyleByStatus({
      qtd_erros_criticos_encontrados,
      qtd_erros_nao_criticos_encontrados,
      qtd_excecoes_encontradas,
      qtd_analises_futuras
    }) {
      let color = 'green';
      if (qtd_erros_criticos_encontrados) {
        color = '#e91e63';
      } else if (qtd_erros_nao_criticos_encontrados) {
        color = '#f9a825';
      } else if (qtd_excecoes_encontradas) {
        color = 'green';
      } else if (qtd_analises_futuras) {
        color = '#ff5722';
      }
      return { color };
    },
    getIconByStatus({
      qtd_erros_criticos_encontrados,
      qtd_erros_nao_criticos_encontrados,
      qtd_excecoes_encontradas,
      qtd_analises_futuras
    }) {
      let icon = 'mdi-check-bold';
      if (qtd_erros_criticos_encontrados) {
        icon = 'mdi-alert-octagon';
      } else if (
        qtd_erros_nao_criticos_encontrados ||
        qtd_excecoes_encontradas ||
        qtd_analises_futuras
      ) {
        icon = 'mdi-alert-rhombus';
      }
      return icon;
    }
  }
};
</script>
