<template>
  <highcharts
    :options="chartOptions"
    style="height: 400px !important"
  />
</template>

<script>
import Highcharts from 'highcharts';

export default {
  name: 'GraficoWaterfallTotalErrosBdgds',
  props: {
    campoErros: {
      type: String,
      required: true,
      default: 'qtd_erros_criticos_encontrados'
    },
    resumoValidacoesBdgds: {
      type: Object,
      required: true
    },
    corBarras: {
      type: String,
      default: '#f9a825'
    }
  },
  computed: {
    empresasValidadas() {
      return Object.keys(this.resumoValidacoesBdgds);
    },
    errosAgrupadosPorEtapa() {
      let errosAgrupadosPorEtapa = {};
      this.empresasValidadas.forEach((empresa) => {
        const versoesBdgdsEmpresa = Object.keys(
          this.resumoValidacoesBdgds[empresa]
        );
        versoesBdgdsEmpresa.forEach((bdgdVersaoId) => {
          const resultadosValidacoes =
            this.resumoValidacoesBdgds[empresa][bdgdVersaoId];
          resultadosValidacoes.forEach((resultadoValidacao) => {
            const siglaEtapa = resultadoValidacao.etapa_nval
              .split('-')[1]
              .trim();
            if (!errosAgrupadosPorEtapa[siglaEtapa]) {
              errosAgrupadosPorEtapa[siglaEtapa] = 0;
            }
            errosAgrupadosPorEtapa[siglaEtapa] += Number(
              resultadoValidacao[this.campoErros]
            );
          });
        });
      });
      return errosAgrupadosPorEtapa;
    },
    etapasValidadas() {
      return Object.keys(this.errosAgrupadosPorEtapa);
    },
    errosOrdemDecrescente() {
      return Object.fromEntries(
        Object.entries(this.errosAgrupadosPorEtapa).sort((a, b) => b[1] - a[1])
      );
    },
    totalErros() {
      return Object.values(this.errosOrdemDecrescente).reduce(
        (acumulador, item) => (acumulador += Number(item)),
        0
      );
    },
    chartOptions() {
      return {
        chart: {
          type: 'waterfall',
          style: {
            fontFamily: 'muli'
          }
        },
        title: {
          text: ''
        },
        subtitle: {
          text: ''
        },
        xAxis: {
          type: 'category'
        },
        yAxis: {
          title: {
            text: null
          }
        },
        legend: {
          enabled: false
        },
        tooltip: {
          pointFormat: '<b>{point.y}</b> '
        },
        series: [
          {
            borderWidth: 0,
            upColor: '#CACFD2',
            color: '#F1948A',
            data: this.seriesData,
            dataLabels: {
              enabled: true,
              formatter: function () {
                return Highcharts.numberFormat(this.y, 0, ',');
              },
              color: '#212121',
              style: {
                textOutline: false
              }
            },
            pointPadding: 0
          }
        ]
      };
    },
    seriesData() {
      const seriesData = [];
      Object.entries(this.errosOrdemDecrescente).forEach((etapa) => {
        const nomeEtapa = etapa[0];
        const numErros = etapa[1];
        if (numErros <= 0) return false;
        seriesData.push({
          name: nomeEtapa,
          color: this.corBarras,
          y: numErros
        });
      });
      seriesData.push({
        name: 'Total',
        color: this.corBarras,
        isSum: true
      });
      return seriesData;
    }
  }
};
</script>
